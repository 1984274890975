import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BlogHero from '../../components/sections/blog-hero';
import { BlogPost } from '../../models/blog-post';
import SEO from '../../components/seo';
import { gaButtonEvent } from '../../services/visitor-info';
import './articlestyle.css';

const TemplateBlog = () => {
  const data = useStaticQuery(graphql`
    query {
      sspotFakeIDs: file(relativePath: { eq: "blog/7-spot-fake-ids.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const blogHeroPost: BlogPost[] = [
    {
      heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostr',
      miniDescription: 'This should really only be about 5 words',
      image: data.sspotFakeIDs.childImageSharp.fluid,
      link: '/get-pricing',
    },
  ];

  return (
    <div>
      <SEO
        title="How to Spot Fake IDs"
        description="The front line for bars and nightclubs. Spot minors, identify flagged patrons and catch fake IDs. 24/7 support. Hear what venues are saying about Patronscan’s ID scanners for bars"
        socialImg="7-spot-fake-ids.png"
      />
      <div className="flex justify-center mx-sm mb-sm mt-top lap:mx-md mb-md mt-toplg desk:mx-lg mb-lg mt-toplg">
        <BlogHero blogMeta={blogHeroPost}></BlogHero>
      </div>
      <div className="flex justify-center">
        <div className="mx-sm mb-sm mt-top lap:mx-md mb-md mt-toplg desk:mx-lg mb-lg mt-toplg article-container">
          <h2 className="article-heading font-heading text-sh lap:text-mh mb-6">
            7 Tips for Spotting Fake IDs
          </h2>
          <p className="article-body text-sb lap:text-mb">
            Fake IDs are easier to obtain than ever. Online reviews, cryptocurrencies, and quick
            deliveries make buying quality counterfeit IDs convenient and relatively safe.
            <br />
            <br />
            Fake ID producers across the world use sophisticated graphics software, printing
            presses, and specialized materials to create near-exact replicas of a real ID. These
            print shops have honed their craft so well that the unsuspecting eye can't differentiate
            between a fake ID and the real thing.
            <br />
            <br />
            With 15+ years of experience catching over 300,000 fake IDs, we thought we’d offer up
            some tips on how you can best catch them:
            <br></br>
            <br></br>
          </p>
          <h2 className="article-heading font-subheading text-sh lap:text-msh mb-4 mt-2">
            1. Observe the Individual's Behavior
          </h2>
          <p className="article-body text-sb lap:text-mb">
            When an individual presents a fake ID, they are likely not confident that you will
            accept it without question. Expect a person with a fake ID to be fidgety, impatient, or
            nervous. Often, they will avoid direct eye contact by looking up and around as they
            interact with you.
            <br></br>
            <br></br>
          </p>
          <h2 className="article-heading font-subheading text-sh lap:text-msh mb-4">
            2. Ask Questions
          </h2>
          <p className="article-body text-sb lap:text-mb">
            Train your staff to ask probing questions related to the ID or issuer. For example, have
            the individual verify the information on the card. If the ID is from out of their home
            state, often the case, there is a good chance that they haven’t memorized area codes,
            state symbolism, or taglines.
            <br></br>
            <br></br>
          </p>
          <h2 className="article-heading font-subheading text-sh lap:text-msh mb-4">
            3. Examine the ID Physically and Visually
          </h2>
          <p className="article-body text-sb lap:text-mb">
            Bend the card to see how it behaves. Does it feel lighter or heavier than a real ID?
            Does the laminated surface bubble when bent? Authentic IDs of higher-quality
            polycarbonate should feel sturdier than plastic. Polycarbonate also makes a metallic
            sound when it strikes a hard surface. Lower quality fake IDs don’t use the right
            polycarbonate materials.
            <br></br>
            <br></br>
          </p>
          <h2 className="article-heading font-subheading text-sh lap:text-msh mb-4">
            4. Use an ID Scanner
          </h2>
          <p className="article-body text-sb lap:text-mb">
            A good ID scanner can tell you if the ID has faults in commonly-used security features
            like laser perforations, micro prints, and proprietary fonts. ID scanners that perform
            OCR analysis can detect these faults whereas barcode scanners can’t.{' '}
            <a className="text-blue-old" href="https://www.patronscan.com">
              Patronscan
            </a>{' '}
            uses pixel-level OCR analysis and an extensive fake ID library to accurately detect fake
            IDs.
            <br></br>
            <br></br>
          </p>
          <h2 className="article-heading font-subheading text-sh lap:text-msh mb-4">
            5. Look for Laser Engravings
          </h2>
          <p className="article-body text-sb lap:text-mb">
            Laser engraving creates a security feature you can feel. When you run your finger over
            the birthdate or other text elements on a driver's license, you should feel the raised
            texture.
            <br></br>
            <br></br>
          </p>
          <h2 className="article-heading font-subheading text-sh lap:text-msh mb-4">
            6. Check Holograms and Ghost Images
          </h2>
          <p className="article-body text-sb lap:text-mb">
            These are some of the more difficult features to replicate. Many of the popular printers
            have the capability to create exact replicas but some fake IDs have poor reproductions
            that are much more obvious.
            <br></br>
            <br></br>
          </p>
          <h2 className="article-heading font-subheading text-sh lap:text-msh mb-4">
            7. Inspect Fonts
          </h2>
          <p className="article-body text-sb lap:text-mb">
            Counterfeit printers are unlikely to have access to, or even the ability to properly
            reproduce, the fonts used by an official ID issuer. This is another security measure
            that printers struggle with. Look for fonts that are substitutes for the real thing.
            Often, counterfeiters trace these fonts and make mistakes that can be found by
            inspecting the characters closely.
            <br></br>
            <br></br>
          </p>
          <p className="mt-2">
            Avoid fines, fraud, and chargebacks arising from the growing use of fake IDs. Your
            observational skills and an ID scanner can help prevent your business from falling
            victim to scammers.
            <br></br>
            <br></br>
            If you'd like to learn more about how we reliably catch fake IDs using Patronscan,{' '}
            <a
              onClick={() => {
                gaButtonEvent(
                  'Speakwithoneofourexperts',
                  'HowToSpotFakePost:Speakwithoneofourexperts',
                );
              }}
              className="text-blue-old"
              href="https://www.patronscan.com/talk-to-an-expert"
            >
              speak to one of our experts
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default TemplateBlog;
